import React from "react"
import { graphql } from "gatsby"
import { Container } from "../components/Utilities/container"
import Seo from "../components/Utilities/seo"

const ProyectosPage = ({ data }) => {
  //const siteTitle = data.site.siteMetadata?.title || `Title`
  // const posts = data.allMarkdownRemark.nodes

  return (
    <>
      <Seo title="Proyectos" />
      <div>Aqui van los proyectos</div>
    </>
  )
}

export default ProyectosPage

// export const pageQuery = graphql`
//   query {
//     site {
//       siteMetadata {
//         title
//       }
//     }
//     allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
//       nodes {
//         excerpt
//         fields {
//           slug
//         }
//         frontmatter {
//           date(formatString: "MMMM DD, YYYY")
//           title
//           description
//         }
//       }
//     }
//   }
// `
